<template>
  <h1>
    <strong>{{ tksMsgAr }}</strong>
  </h1>
  <h4>
    <strong>{{ tksMsgEn }}</strong>
  </h4>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Results",
  emits: ["onShowFeedback"],
  components: {},

  data() {
    return {
      tksMsgAr: "شكرا لك على ملاحظاتك القيمة",
      tksMsgEn: "Thank you for your valuable feedback",
      showFeedback: false,
      t: 0,
    };
  },
  mounted() {
    //console.log("Block mounted");
    setTimeout(() => {
      this.showFeedback = true;
      this.startTimer();
    }, 10);
  },
  unmounted() {
    clearInterval(this.t);
  },
  methods: {
    startTimer() {
      this.t = setInterval(() => {
        this.showFeedback = true;
        //console.log("....1");
        this.$emit("onShowFeedback", this.showFeedback);
      }, 4000);
    },
  },
});
</script>

<style></style>
