<template>
  <h2>
    <strong>{{ titleAr }}</strong>
  </h2>
  <div class="reasonsDiv" dir="rtl">
    <div v-for="reason in reasons" :key="reason.id" class="reasonsCheck">
      <input
        type="checkbox"
        :value="reason.reasonText"
        @change="check($event, reason.reasonText)"
        v-model="reasonsVal"
      />
      <label>{{ reason.reasonText }}</label>
    </div>
    <div v-if="showOrherReason" class="bunDiv">
      <textarea
        cols="30"
        rows="5"
        type="text"
        name="orherReason"
        placeholder="برجاء ذكر الأسباب"
        v-model="otherReason"
      />
    </div>
    <div>
      <input
        type="text"
        name="mobile"
        placeholder="رقم الهاتف المحمول"
        v-model="mobileNo"
      />
      <!-- <label>رقم الهاتف المحمول</label> -->
    </div>
    <div class="bunDiv">
      <button @click="sendReasons">إرسال</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    reasons: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  name: "FeedbackReason",
  emits: ["onSendReasons"],

  data() {
    return {
      titleAr:
        "نسعى دائما لرضاكم و تقديم خدمات متميزة لزبائننا, نرجو مساعدتنا في ذلك باختيار الأسباب",
      titleEn:
        "We always strive for your satisfaction and provide excellent services to our customers, please help us in this by choosing the reasons",
      reasonsVal: [],
      mobileNo: "",
      otherReason: "",
      showOrherReason: false,
    };
  },
  methods: {
    sendReasons() {
      if (this.otherReason !== "") {
        //console.log(this.otherReason);
        this.reasonsVal.push(this.otherReason);
      }

      // console.log(this.reasonsVal);

      this.$emit("onSendReasons", this.reasonsVal, this.mobileNo);
    },

    check(e, res) {
      //console.log(e.target.checked, res);
      if (e.target.checked && res === "اخرى") {
        this.showOrherReason = true;
      } else if (!e.target.checked && res === "اخرى") {
        this.showOrherReason = false;
      }
    },
  },
};
</script>

<style>
input[type="checkbox"] {
  display: inline-block;
  width: 16px;
  margin: 0;
  position: relative;
  top: 2px;
  background-color: #e31d1a;
}

label {
  display: inline-block;
  margin: 5px 5px 5px;
  font-size: 1.3em;
  letter-spacing: 1px;
}

input[type="text"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
}

button {
  background-color: #e31d1a; /* Green */
  border-radius: 7px;
  min-width: 150px;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}
.reasonsDiv {
  text-align: right;
}

.bunDiv {
  margin-top: 10px;
}
</style>
