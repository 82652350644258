/* eslint-disable prettier/prettier */
import { createRouter, createWebHashHistory } from "vue-router";
import FeedbackPage from "../views/FeedbackPage.vue";

import Question from "../views/Question.vue";
import Login from "../views/Login.vue";
import Dashboard from "../views/Dashboard.vue";

import { Auth } from "@aws-amplify/auth";

const routes = [
  {
    path: "/question",
    name: "Question",
    component: Question,
    meta: { requiresAuth: true },
  },
  {
    //path: "/",
    path: "/FeedbackPage/:branch/:qid",
    name: "FeedbackPage",
    component: FeedbackPage,

    meta: { requiresAuth: true },
  },
  {
    path: "/",
    name: "Home",
    component: Login,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { requiresAdmin: true },
  },
];

const router = createRouter({
  //history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  //const requiredAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAutenticated = await Auth.currentUserInfo();

  let adminGrp = false;
  if (isAutenticated) {
    const user = await Auth.currentAuthenticatedUser();

    //console.log("user", user)

    if (user.signInUserSession.accessToken.payload["cognito:groups"]) {
      adminGrp =
        user.signInUserSession.accessToken.payload["cognito:groups"][0] ==
        "Admin";
    } else {
      adminGrp = false;
    }

    //console.log("isAutenticated", adminGrp)
  }

  if (to.matched.some((record) => record.meta.requiresAdmin)) {
    if (!adminGrp) {
      next({ path: "/" });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isAutenticated) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }

  // if (to.path == '/dashboard' && !adminGrp) {
  //     next("/login");
  // } else {

  //     if (requiredAuth && !isAutenticated) {
  //         next("/login");
  //     } else {

  //         next();
  //     }
  // }
});

export default router;
