// @ts-check
import { initSchema } from "@aws-amplify/datastore";
import { schema } from "./schema";

const {
  CustServices,
  FbUsers,
  Location,
  UserFeedback,
  Reason,
  Answer,
  Questions,
  AnswerReason,
} = initSchema(schema);

export {
  CustServices,
  FbUsers,
  Location,
  UserFeedback,
  Reason,
  Answer,
  Questions,
  AnswerReason,
};
