<template>
  <div class="container mt-5">
    <Nav />
    <div class="mt-3">
      <div v-if="questions">
        <div
          v-for="question in questions"
          :key="question.id"
          class="card-group"
        >
          <QuestionComp :question="question"></QuestionComp>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DataStore } from "@aws-amplify/datastore";
import { Questions } from "../models";
import QuestionComp from "../components/QuestionComp.vue";
import Nav from "../components/Nav.vue";

export default {
  name: "Home",
  components: { QuestionComp, Nav },
  data() {
    return {
      questions: [],
    };
  },
  methods: {
    async getQuestion() {
      //console.log('methods');
      const qtn = await DataStore.query(Questions);
      //console.log('methods1');
      //console.log("qtn", qtn);
      if (qtn.length > 0) {
        this.questions = qtn;
        //console.log("questions", this.questions);
      }
    },
  },
  created() {
    this.getQuestion();
  },
};
</script>

<style></style>
