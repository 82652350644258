import { createStore } from "vuex";
import { auth } from "./auth/auth";
import apiauth from "./auth/apiauth";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    apiauth,
  },
});
