export const schema = {
  models: {
    CustServices: {
      name: "CustServices",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        CustID: {
          name: "CustID",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        CustName: {
          name: "CustName",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        ServicesID: {
          name: "ServicesID",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        CSAName: {
          name: "CSAName",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        DateTime: {
          name: "DateTime",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
        },
        erpUserName: {
          name: "erpUserName",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        DeviceUserID: {
          name: "DeviceUserID",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "CustServices",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    FbUsers: {
      name: "FbUsers",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        userid: {
          name: "userid",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        displayName: {
          name: "displayName",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        userName: {
          name: "userName",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        erpUserName: {
          name: "erpUserName",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "FbUsers",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Location: {
      name: "Location",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        locationId: {
          name: "locationId",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        locationName: {
          name: "locationName",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "Locations",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    UserFeedback: {
      name: "UserFeedback",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        qid: {
          name: "qid",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        feedBackValue: {
          name: "feedBackValue",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        location: {
          name: "location",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        DateTime: {
          name: "DateTime",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
        },
        user: {
          name: "user",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        mobileNo: {
          name: "mobileNo",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        otherReasons: {
          name: "otherReasons",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        reasons: {
          name: "reasons",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        feedBack: {
          name: "feedBack",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        questionText: {
          name: "questionText",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        erpUserName: {
          name: "erpUserName",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        ServicesID: {
          name: "ServicesID",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        CSAName: {
          name: "CSAName",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        DeviceUserID: {
          name: "DeviceUserID",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        CustServicesID: {
          name: "CustServicesID",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        CustName: {
          name: "CustName",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "UserFeedbacks",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
              {
                allow: "private",
                operations: ["create", "read", "update"],
              },
            ],
          },
        },
      ],
    },
    Reason: {
      name: "Reason",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        rid: {
          name: "rid",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        reasonText: {
          name: "reasonText",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        answers: {
          name: "answers",
          isArray: true,
          type: {
            model: "AnswerReason",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "reason",
          },
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "Reasons",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Answer: {
      name: "Answer",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        image: {
          name: "image",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        text: {
          name: "text",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        rating: {
          name: "rating",
          isArray: false,
          type: "Int",
          isRequired: false,
          attributes: [],
        },
        questionsID: {
          name: "questionsID",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        Reasons: {
          name: "Reasons",
          isArray: true,
          type: {
            model: "AnswerReason",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "answer",
          },
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "Answers",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "byQuestions",
            fields: ["questionsID"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Questions: {
      name: "Questions",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        qid: {
          name: "qid",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        questionAr: {
          name: "questionAr",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        question: {
          name: "question",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        Answers: {
          name: "Answers",
          isArray: true,
          type: {
            model: "Answer",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "questionsID",
          },
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "Questions",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "public",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    AnswerReason: {
      name: "AnswerReason",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        reason: {
          name: "reason",
          isArray: false,
          type: {
            model: "Reason",
          },
          isRequired: true,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "reasonID",
          },
        },
        answer: {
          name: "answer",
          isArray: false,
          type: {
            model: "Answer",
          },
          isRequired: true,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "answerID",
          },
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "AnswerReasons",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "byReason",
            fields: ["reasonID"],
          },
        },
        {
          type: "key",
          properties: {
            name: "byAnswer",
            fields: ["answerID"],
          },
        },
      ],
    },
  },
  enums: {},
  nonModels: {},
  codegenVersion: "3.3.1",
  version: "aba7d1e998f37650c68322ddeb4ab607",
};
