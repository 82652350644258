<template>
  <div>
    <div class="customerFeedback">
      <div id="bannerImageFull" v-if="!custServices"></div>
      <div v-else-if="questions" id="panel" class="panelcontainer">
        <div v-if="custServices">
          <h5>
            مرحبًا <strong>{{ custServices.CustName }} </strong>
          </h5>
          <h5>
            <strong> {{ custServices.ServicesID }} : </strong>
            يرجى تخصيص بعض الوقت لتزويدنا بتعليقاتك على رقم الخدمة
          </h5>
          <button
            type="button"
            class="m-2 btn-sm"
            :key="questions.id"
            @click="
              sendFeedBack(
                skipRating,
                questions.id,
                [],
                questions.questionAr,
                skipText
              )
            "
          >
            ليس الآن ، ربما في المرة القادمة
          </button>
          <hr />
        </div>
        <Feedback
          v-if="showFeedbackComp"
          @onFeedBack="sendFeedBack"
          :question="questions"
          key="0"
        />
        <Results v-if="showResults" @onShowFeedback="showFeedback" />
        <FeedbackReason
          v-if="showReasons"
          @onSendReasons="SendReasons"
          :reasons="fbReasons"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Feedback from "../components/Feedback";
import FeedbackReason from "../components/FeedbackReason";
import Results from "../components/Results";
import { DataStore } from "@aws-amplify/datastore";
import {
  Questions,
  Answer,
  AnswerReason,
  UserFeedback,
  CustServices,
} from "../models";
//import { mapGetters } from "vuex";
import { Auth } from "@aws-amplify/auth";
//import Axios from "axios";
import { mapActions, mapGetters } from "vuex";
import axiosinstance from "../services/axiosinstance";

export default {
  name: "FeedbackPage",
  components: { Feedback, Results, FeedbackReason },
  data() {
    return {
      bannerImg: require("../assets/feedback.jpg"),
      isAnswered: false,
      delay: null,
      score: null,
      branch: "",
      showResults: false,
      showReasons: false,
      showFeedbackComp: true,
      userFeedback: {
        questionsId: "CF001",
        feedBackValue: "",
        location: "Libya",
        DateTime: new Date(),
        user: "Rifaz",
        mobileNo: "",
        reasons: [],
        questionText: "",
        feedBack: "",
      },
      questions: {},
      fbReasons: [],
      error: "",
      user: null,
      custServices: null,
      fbUser: null,
      timr: 0,
      skipRating: 0,
      skipText: "Skip",

      //ref: firebaseApp.firestore().collection("feedBack"),
      //ref1: firebaseApp.firestore().collection("questions"),
    };
  },
  methods: {
    async onCreate() {
      //const models = await DataStore.query(AnswerReason);
      //console.log("models", models);
      //DataStore.stop()
      //DataStore.start()
      // const post = await DataStore.save(
      //   new Questions({
      //     qid: "QID5",
      //     questionAr: "Lorem ipsum dolor sit amet",
      //     question:
      //       "How satisfied are you with our customer support performance?",
      //     Answers: [],
      //   })
      // );
      // console.log("post", post);
    },
    ...mapActions({
      apiSignIn: "apiauth/apiSignIn",
    }),
    ...mapActions("auth", ["authAction"]),
    startTimer() {
      this.timr = setInterval(() => {
        this.custServices = null;
        this.showResults = false;
        this.showReasons = false;
        this.$emit("oncustServicesUpdated", false);
        clearInterval(this.timr);
        //console.log("....1");
      }, 600000);
    },

    async getQuestion(qid) {
      //console.log("qid", qid);
      const qtn = await DataStore.query(Questions, (c) => c.qid("eq", qid));
      if (qtn.length > 0) {
        let answerReason = await DataStore.query(AnswerReason);
        //console.log("answerReason", answerReason);

        let reasons = [];

        answerReason.forEach((element) => {
          //console.log("element", element);
          if (element.answer) {
            let reason = {
              id: element.id,
              answerId: element.answer.id,
              rid: element.reason.rid,
              reasonText: element.reason.reasonText,
            };
            reasons.push(reason);
          }
        });

        //console.log("reasons", reasons);

        const _qtn = qtn[0];

        //console.log(_qtn);
        const answers = await DataStore.query(Answer, (c) =>
          c.questionsID("eq", _qtn.id)
        );

        // console.log("answers", answers);

        let ans = [];
        answers.forEach((element) => {
          const reason = reasons.filter((r) => r.answerId == element.id);
          //console.log("reasonreason", reason)
          let answer = {
            id: element.id,
            image: element.image,
            questionsID: element.questionsID,
            rating: element.rating,
            text: element.text,
            reasons: reason,
          };
          ans.push(answer);
        });
        const sorter2 = (sortBy) => (a, b) => a[sortBy] > b[sortBy] ? 1 : -1;

        ans.sort(sorter2("rating"));

        this.questions = {
          id: _qtn.id,
          qid: _qtn.qid,
          questionAr: _qtn.questionAr,
          question: _qtn.question,
          Answers: ans,
        };
      }

      //console.log("questions", this.questions);
      //console.log("qtn", qtn);
    },

    async sendFeedBack(rating, qid, reasons = [], questionText, feedBackText) {
      this.userFeedback.feedBackValue = rating;
      this.userFeedback.questionsId = qid;
      this.userFeedback.location = this.branch;
      this.userFeedback.questionText = questionText;
      this.userFeedback.feedBack = feedBackText;

      //console.log(this.custServices.CustName);

      if (reasons.length > 0) {
        this.fbReasons = reasons;
        this.showFeedbackComp = false;
        this.showResults = false;
        this.showReasons = true;
      } else {
        this.showFeedbackComp = false;
        this.showResults = true;

        //console.log("this.userFeedback", this.userFeedback);

        let reasonsJsonString = JSON.stringify(reasons);

        try {
          const feedBackRes = await DataStore.save(
            new UserFeedback({
              qid: this.userFeedback.questionsId,
              feedBackValue: this.userFeedback.feedBackValue,
              location: this.userFeedback.location,
              DateTime: new Date().toISOString(),
              user: this.user.username,
              mobileNo: "",
              otherReasons: "",
              reasons: reasonsJsonString,
              questionText: this.userFeedback.questionText,
              feedBack: this.userFeedback.feedBack,
              erpUserName: this.custServices.erpUserName,
              ServicesID: this.custServices.ServicesID,
              CSAName: this.custServices.CSAName,
              DeviceUserID: this.custServices.DeviceUserID,
              CustServicesID: this.custServices.id,
              CustName: this.custServices.CustName,
            })
          );
          console.log("feedBackRes1", feedBackRes);
          await this.createcustomerfeedback(feedBackRes);
          //console.log("responce", responce);
        } catch (error) {
          console.log("error", error);
        }
      }
      //this.custServices = null;
    },
    async SendReasons(reasonsVal = [], mobileNo) {
      //console.log(mobileNo);
      //console.log(this.custServices.CustName);

      this.userFeedback.reasons = reasonsVal;
      this.userFeedback.mobileNo = mobileNo;

      this.showFeedbackComp = false;
      this.showReasons = false;

      let reasonsJsonString = JSON.stringify(reasonsVal);
      try {
        const feedBackRes = await DataStore.save(
          new UserFeedback({
            qid: this.userFeedback.questionsId,
            feedBackValue: this.userFeedback.feedBackValue,
            location: this.userFeedback.location,
            DateTime: new Date().toISOString(),
            user: this.user.username,
            mobileNo: mobileNo,
            otherReasons: "",
            reasons: reasonsJsonString,
            questionText: this.userFeedback.questionText,
            feedBack: this.userFeedback.feedBack,
            erpUserName: this.custServices.erpUserName,
            ServicesID: this.custServices.ServicesID,
            CSAName: this.custServices.CSAName,
            DeviceUserID: this.custServices.DeviceUserID,
            CustServicesID: this.custServices.id,
            CustName: this.custServices.CustName,
          })
        );
        console.log("feedBackRes", feedBackRes);
        await this.createcustomerfeedback(feedBackRes);
      } catch (error) {
        console.log("error", error);
      }

      this.showResults = true;
      //this.custServices = null;
    },
    showFeedback() {
      this.showResults = false;
      this.showReasons = false;
      console.log("this.custServices = null", this.custServices);
      this.$emit("oncustServicesUpdated", false);
      this.showResults = false;
      this.showReasons = false;
      this.custServices = null;
      this.showFeedbackComp = true;
    },

    async saveFeedback(reasonsVal = [], mobileNo) {
      await DataStore.save(
        new UserFeedback({
          qid: this.userFeedback.questionsId,
          feedBackValue: this.userFeedback.feedBackValue,
          location: this.userFeedback.location,
          DateTime: new Date().toISOString(),
          user: this.user.username,
          mobileNo: mobileNo,
          otherReasons: "",
          reasons: reasonsVal,
        })
      );
    },
    async createcustomerfeedback(data) {
      console.log("createcustomerfeedback", data);
      try {
        axiosinstance
          .post("/customer/createcustomerfeedback", {
            id: data.id,
            createdAt: data.DateTime,
            dataAreaId: data.dataAreaId,
            deviceId: data.DeviceUserID,
            feedback: data.feedBack,
            feedbackValue: data.feedBackValue,
            location: data.location,
            otherReasons: data.otherReasons,
            phone: data.mobileNo,
            questionId: data.qid,
            questionText: data.questionText,
            reasons: data.reasons.toString(),
            serviceOrderId: data.ServicesID,
            updatedAt: data.DateTime,
            userId: data.user,
          })
          .then(function (response) {
            console.log(response);
            return;
          })
          .catch(function (error) {
            console.log(error);
          });
      } catch (error) {
        console.log("error", error);
      }
    },
  },

  computed: {
    ...mapGetters({
      isAuthenticate: "apiauth/getAuthenticate",
    }),
  },

  async mounted() {
    this.user = await Auth.currentAuthenticatedUser();
    this.apiSignIn();
    //this.createcustomerfeedback("Rifaz");
  },

  async created() {
    //var currentUrl = window.location.pathname;
    const qid = this.$route.params.qid;
    this.branch = this.$route.params.branch;
    this.user = await Auth.currentAuthenticatedUser();
    this.getQuestion(qid);
    this.getQuestion(qid);
    this.getQuestion(qid);
    this.$emit("oncustServicesUpdated", false);

    // const usr = await DataStore.query(FbUsers, (u) =>
    //   u.userName("eq", this.user.username)
    // );

    // console.log("fbUser1", usr);
    // console.log("this.user", this.user);

    //Subscribe to changes
    this.subscription = DataStore.observe(CustServices).subscribe((msg) => {
      //console.log(msg.model, msg.opType, msg.element);
      //console.log("model", msg.element);
      //console.log("model", msg.element.erpUserName);
      if (
        msg.opType == "INSERT" &&
        msg.element.DeviceUserID == this.user.username
      ) {
        this.custServices = msg.element;
        this.showResults = false;
        this.showReasons = false;
        this.showFeedbackComp = true;
        this.$emit("oncustServicesUpdated", true);
        console.log("this.tier", this.timr);

        setTimeout(() => {
          this.startTimer();
        }, 600000);
      } else {
        this.custServices = null;
        this.showResults = false;
        this.showReasons = false;
        this.$emit("oncustServicesUpdated", false);
      }
    });

    //console.log("this.custServices", this.custServices);

    // this.subscription1 = DataStore.observeQuery(CustServices, (s) =>
    //   s.erpUserName("eq", usr[0].erpUserName)
    // ).subscribe((snapshot) => {
    //   const { items, isSynced } = snapshot;
    //   console.log("items", items);
    //   console.log(
    //     `[Snapshot] item count: ${items.length}, isSynced: ${isSynced}`
    //   );
    //   //this.custServices = msg.element;
    // });
  },
  unmounted() {
    if (!this.subscription) return;
    this.subscription.unsubscribe();

    this.custServices = null;
    clearInterval(this.timr);

    // if (!this.subscription1) return;
    // this.subscription1.unsubscribe();
  },
};
</script>

<style>
.creditNote {
  color: white;
}
</style>
