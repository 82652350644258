import Axios from "axios";
import store from "../store";
//import { authStore } from "../store/apiauth";

const axiosinstance = Axios.create({
  baseURL: "https://mersinconnect.azurewebsites.net/api/ly/",
  //baseURL: "https://localhost:5001/api/ly/",
  //timeout: 60000,
});

// async function currentAccessToken() {
//   await store.dispatch("apiauth/getCurrentAccessToken");
//   token = await store.getters["apiauth/getToken"];
//   console.log("jwtToken00", store.getters.getToken);
// }

axiosinstance.interceptors.request.use(
  async (config) => {
    const value = await store.dispatch("apiauth/getCurrentAccessToken");
    const token = value.jwtToken;
    //console.log("Session token", token);
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// axiosinstance.interceptors.response.use(
//   function (response) {
//     return response;
//   },
//   function (error) {
//     if (401 === error.response.status) {
//       console.log("Session Expired");
//       //window.location = '/login'
//     } else {
//       return Promise.reject(error);
//     }
//   }
// );

// function getCurrentAccessToken() {
//   let jwtToken = "";

//   Axios.post("https://mersinconnect.azurewebsites.net/api/token/authenticate", {
//     username: "77ACB65E-A67F-4CCB-A4FD-01F749DFE756",
//     password: "B34E4D2A-7D3B-46FE-B365-3AA815A8A998",
//   })
//     .then(function (response) {
//       console.log("jwtToken", response);
//       console.log("jwtToken1", response.data.jwtToken);
//       jwtToken = response.data.jwtToken;
//       return jwtToken;
//     })
//     .catch(function (error) {
//       console.log(error);
//       return "";
//     });

//   return jwtToken;
// }

export default axiosinstance;
