<template>
  <div v-if="showLogo" id="nav">
    <router-link to="/">
      <a class="float-end mt-2"
        ><i class="fas fa-home fa-2x" style="color: white"></i
      ></a>
    </router-link>
    <img v-if="showLogo" :src="mySVG" alt="My Hommer" class="logo" />
  </div>

  <router-view @oncustServicesUpdated="oncustServicesUpdated" />
  <div
    id="creditNote"
    :class="showLogo ? 'credit' : 'creditTop'"
    style="color: white"
  >
    © {{ currentYear }} Hommer. All rights reserved.
  </div>
</template>

<script>
///import Nav from "./components/Nav.vue";
import { mapActions } from "vuex";
//import axiosinstance from "./services/axiosinstance";

export default {
  mounted() {
    this.authAction();
    // axiosinstance
    //   .get("products/getinventory?businessType=1")
    //   .then(function (response) {
    //     console.log("getinventory", response);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  },
  name: "App",
  data() {
    return {
      mySVG: require("./assets/hommer_logo_red.svg"),
      showLogo: true,
      currentYear: new Date().getFullYear(),
    };
  },
  methods: {
    ...mapActions("auth", ["authAction"]),
    oncustServicesUpdated(showLogo) {
      this.showLogo = showLogo;
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #444;
  margin: 0;
}

#nav {
  padding: 30px;
  background-color: #e31d1a;
}

.logo {
  max-height: 60px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

#bannerImageFull {
  /*margin-top: -60px;*/
  background-image: url("./assets/feedback.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #e31d1a;
  height: 100vh;
  background-attachment: fixed;
}
</style>
