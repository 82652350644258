<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex justify-content-between px-md-1">
        <div>
          <h3 class="text-danger" style="text-align: left">{{ cardVal }}</h3>
          <p class="mb-0">{{ title }}</p>
        </div>
        <div class="align-self-center">
          <img
            :src="require('../assets/' + imageName)"
            :alt="title"
            style="width: 48px"
          />
        </div>
      </div>
      <div class="px-md-1">
        <div class="progress mt-3 mb-1 rounded" style="height: 7px">
          <div
            class="progress-bar"
            role="progressbar"
            :style="styleObject"
            :aria-valuenow="valuenow"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "card",
  props: {
    title: {
      type: String,
      description: "Card title",
    },
    imageName: {
      type: String,
      description: "Image Name",
    },
    type: {
      type: String,
      description: "Card type (e.g 1,2,3 etc)",
    },
    cardVal: {
      type: Number,
      description: "Current Value",
    },
    totalVal: {
      type: Number,
      description: "Total Value",
    },
  },
  data() {
    return {
      valuenow: 0,
      color: "gray",
      styleObject: {
        //width: "100%",
        "background-color": "red",
      },
    };
  },
  async mounted() {
    var cVla = await parseInt(this.cardVal);
    var tVla = await parseInt(this.totalVal);

    switch (this.type) {
      case "1":
        this.color = "#60b258";
        break;
      case "2":
        this.color = "#b1dd77";
        break;
      case "3":
        this.color = "#ebe27a";
        break;
      case "4":
        this.color = "#e8a86b";
        break;
      case "5":
        this.color = "#e86c62";
        break;
      case "6":
        this.color = "gray";
        break;

      default:
        this.color = "gray";
    }

    this.valuenow = (100 / tVla) * cVla;

    this.styleObject = {
      width: this.valuenow.toString() + "%",
      "background-color": this.color,
    };

    //console.log(this.valuenow);
  },

  // async created() {
  //   var cVla = await parseInt(this.cardVal);
  //   var tVla = await parseInt(this.totalVal);

  //   this.valuenow = (100 / tVla) * cVla;
  //   this.styleObject = {
  //     width: this.valuenow.toString() + "%",
  //     "background-color": this.color,
  //   };
  // },
};
</script>
