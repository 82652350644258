<template>
  <Bar :chart-data="datasets" :chart-options="chartOptions" />
</template>

<script>
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "BarChart",
  components: { Bar },
  props: {
    chartsData: {
      type: Object,
      required: true,
    },
    chartOptions: {
      responsive: true,
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      description: "Chart title",
    },
  },
  data() {
    return {
      datasets: () => {},
    };
  },
  mounted() {
    if (this.chartsData) {
      const fbText = this.chartsData.map((d) => d.feedBack);
      const count = this.chartsData.map((d) => d.val);

      this.datasets = {
        labels: fbText,
        datasets: [
          {
            label: this.title,
            backgroundColor: [
              "#60b258",
              "#b1dd77",
              "#ebe27a",
              "#e8a86b",
              "#e86c62",
            ],
            data: count,
          },
        ],
      };
    }
  },
};
</script>
