<template>
  <div v-if="question">
    <h1>
      <strong dir="rtl">{{ question.questionAr }}</strong>
    </h1>
    <!-- <h4>
      <strong>{{ question.question }}</strong>
    </h4> -->
    <div class="ratingscontainer">
      <div
        v-for="ans in question.Answers"
        :key="ans.id"
        class="rating"
        @click="
          sendFeedBack(
            ans.rating,
            question.qid,
            ans.reasons,
            question.questionAr,
            ans.text
          )
        "
      >
        <a id="btnSave1" href="#"></a>
        <img :src="require('../assets/' + ans.image)" :alt="ans.text" />
        <br />
        <span>{{ ans.text }}</span>
      </div>
    </div>
  </div>
  <div class="qrCode">
    <qrcode-vue :value="value" :size="size" level="H" />
  </div>
  <div>
    <p>
      اذا كنت تفضل تقديم ملاحظاتك على هاتفك المحمول ، فيرجى مسح رمز الاستجابة
      السريعة
    </p>
  </div>
</template>

<script>
import QrcodeVue from "qrcode.vue";

export default {
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  name: "Feedback",
  emits: ["onFeedBack"],
  components: { QrcodeVue },
  data() {
    return {
      arQ: "ما مدى رضاك ​​عن أداء دعم العملاء لدينا؟",
      enQ: "How satisfied are you with our customer support performance?",
      value: "/",
      size: 100,
    };
  },
  created() {
    this.value = window.location.href;
  },
  methods: {
    sendFeedBack(rating, qid, reason = [], questionText, feedBackText) {
      this.$emit("onFeedBack", rating, qid, reason, questionText, feedBackText);
    },
    getImgUrl(pet) {
      var images = require.context("../assets/", false, /\.png$/);
      return images(pet);
    },
  },
};
</script>

<style></style>
