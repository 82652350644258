import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Amplify from "aws-amplify";
import "@aws-amplify/ui-vue";
import "@aws-amplify/ui-vue/styles.css";
import asw_exports from "./aws-exports";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/global.css";
import { DataStore } from "aws-amplify";
import Axios from "axios";

require("@/store/auth/subscriber");

Amplify.configure(asw_exports);
DataStore.configure();

Axios.defaults.baseURL = "https://mersinconnect.azurewebsites.net/api/ly/";
//Axios.defaults.baseURL = "https://localhost:5001/api/ly/";

//Amplify.Logger.LOG_LEVEL = "DEBUG"
store.dispatch("apiauth/attempt", localStorage.getItem("token"));

createApp(App).use(store).use(router).mount("#app");
