<template>
  <div class="card-group">
    <div class="card border-success mb-3" style="max-width: 18rem">
      <div class="card-header">{{ question.qid }}</div>
      <div class="card-body text-success">
        <p class="card-text">
          {{ question.questionAr }}
        </p>
        <p class="card-text">
          {{ question.question }}
        </p>

        <div class="form-outline form-white mb-4">
          <label class="form-label" for="branchId">Location</label>
          <select
            id="branchId"
            required
            v-model="branchId"
            class="form-select"
            aria-label="Default select example"
          >
            <option value="">Selct Location</option>
            <option
              v-for="loc in locations"
              :key="loc.locationId"
              v-bind:value="loc.locationId"
            >
              {{ loc.locationName }}
            </option>
          </select>
        </div>
        <div>
          <div class="mb-3 text-danger">{{ error }}</div>
        </div>
        <button @click="openQuestion" class="btn-blue px-5">Start</button>
      </div>
    </div>
  </div>
</template>

<script>
import { DataStore } from "@aws-amplify/datastore";
import { Location } from "../models";
export default {
  name: "QuestionComp",
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      branchId: "",
      error: "",
      locations: [],
    };
  },
  async created() {
    await this.getLocation();
  },
  methods: {
    openQuestion() {
      if (!this.branchId) {
        this.error = "Please select the location";
      } else {
        this.error = "";
        this.$router.push(
          "/FeedbackPage/" + this.branchId + "/" + this.question.qid
        );
      }
    },
    async getLocation() {
      const lols = await DataStore.query(Location);

      if (lols.length > 0) {
        this.locations = lols;
        console.log("getLocation", this.locations);
      }
    },
  },
};
</script>

<style></style>
