<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <router-link to="/">
        <a class="nav-link active">Home</a>
      </router-link>
      <div class="navbar-nav">
        <span v-if="user" class="navbar-text">
          Welcome {{ user.username }}
        </span>

        <a v-if="user" class="nav-link" @click="logout" href="#">Logout</a>
        <div v-if="!user">
          <router-link to="/Login">
            <a class="nav-link active">Login</a>
          </router-link>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  methods: {
    async logout() {
      await this.$store.dispatch("auth/logout");
      this.$router.push("/Login");
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
};
</script>

<style scoped></style>
