import Axios from "axios";

export default {
  namespaced: true,
  state: {
    token: null,
    refreshToken: null,
    isAuthenticate: false,
  },
  getters: {
    getToken(state) {
      return state.token;
    },
    getRefreshToken(state) {
      return state.refreshToken;
    },
    getAuthenticate(state) {
      return state.token && state.isAuthenticate;
    },
  },
  mutations: {
    SET_TOKEN(state, token) {
      //console.log("SET_TOKEN", token);
      state.token = token;
    },
    SET_RF_TOKEN(state, refreshToken) {
      state.refreshToken = refreshToken;
    },
    SET_IS_AUTHENTICATE(state, isauthenticate) {
      state.isAuthenticate = isauthenticate;
    },
  },

  actions: {
    async getCurrentAccessToken1({ dispatch }) {
      Axios.post("/token/authenticate", {
        username: "usr-33830C3D-A94B-491C-82D3-69AAA1D52BFA",
        password: "B526802B-425F-48AE-92EB-80DB55AAAA47",
      })
        .then((response) => {
          //console.log("token response1", response.data);
          dispatch(
            "attempt",
            response.data.jwtToken,
            response.data.refreshToken
          );
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    async getCurrentAccessToken({ dispatch }) {
      return new Promise((resolve, reject) => {
        Axios.post("/token/authenticate", {
          username: "usr-33830C3D-A94B-491C-82D3-69AAA1D52BFA",
          password: "B526802B-425F-48AE-92EB-80DB55AAAA47",
        })
          .then((response) => {
            //console.log("token response1", response.data);
            dispatch(
              "attempt",
              response.data.jwtToken,
              response.data.refreshToken
            );
            resolve(response.data);
          })
          .catch(function (error) {
            console.error(error);
            reject(error);
          });
      });
    },

    async apiSignIn({ dispatch }) {
      let response = await Axios.post("/token/authenticate", {
        username: "usr-33830C3D-A94B-491C-82D3-69AAA1D52BFA",
        password: "B526802B-425F-48AE-92EB-80DB55AAAA47",
      });
      //console.log(response);

      return dispatch(
        "attempt",
        response.data.jwtToken,
        response.data.refreshToken
      );
    },

    async attempt({ commit, state }, token, refreshtoken) {
      if (token) {
        commit("SET_TOKEN", token);
      }
      if (!state.token) {
        return;
      }
      try {
        let response = await Axios.post("/token/authenticate", {
          username: "usr-33830C3D-A94B-491C-82D3-69AAA1D52BFA",
          password: "B526802B-425F-48AE-92EB-80DB55AAAA47",
        });
        if (response) {
          commit("SET_IS_AUTHENTICATE", true);
          commit("SET_RF_TOKEN", refreshtoken);
        }
      } catch (error) {
        console.log(error);
        commit("SET_TOKEN", null);
        commit("SET_RF_TOKEN", null);
        commit("SET_IS_AUTHENTICATE", false);
      }
    },
  },
};
