<!-- eslint-disable prettier/prettier -->
<!-- eslint-disable prettier/prettier -->
<!-- eslint-disable prettier/prettier -->
<!-- eslint-disable prettier/prettier -->

<template>
  <div class="container-fluid">
    <section>
      <div class="row">
        <div class="col-12 mt-3 mb-1">
          <h5 class="text-uppercase">Customer Feedback</h5>
          <p>Hommer Libya</p>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-2 col-sm-6 col-12 mb-4">
          <Card
            title="Very Happy"
            :cardVal="veryHappy"
            :totalVal="totalVal"
            imageName="VeryHappy_1.png"
            type="1"
            :key="componentKey1"
          />
        </div>
        <div class="col-xl-2 col-sm-6 col-12 mb-4">
          <Card
            title="Happy"
            :cardVal="happy"
            :totalVal="totalVal"
            imageName="Happy_1.png"
            type="2"
            :key="componentKey2"
          />
        </div>
        <div class="col-xl-2 col-sm-6 col-12 mb-4">
          <Card
            title="Average"
            :cardVal="average"
            :totalVal="totalVal"
            imageName="Average_1.png"
            type="3"
            :key="componentKey3"
          />
        </div>
        <div class="col-xl-2 col-sm-6 col-12 mb-4">
          <Card
            title="Sad"
            :cardVal="sad"
            :totalVal="totalVal"
            imageName="Sad_1.png"
            type="4"
            :key="componentKey4"
          />
        </div>
        <div class="col-xl-2 col-sm-6 col-12 mb-4">
          <Card
            title="Very Sad"
            :cardVal="verySad"
            :totalVal="totalVal"
            imageName="VerySad_1.png"
            type="5"
            :key="componentKey5"
          />
        </div>
        <div class="col-xl-2 col-sm-6 col-12 mb-4">
          <Card
            title="Total"
            :cardVal="totalVal"
            :totalVal="totalVal"
            imageName="calculator.svg"
            type="6"
            :key="componentKey6"
          />
        </div>
      </div>
    </section>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-xl-3 col-sm-6 col-12 mb-4"></div>
      <div class="col-xl-3 col-sm-6 col-12 mb-4">
        <div class="form-outline form-white mb-4">
          <label class="form-label" for="branchId">Location</label>
          <select
            @change="onLocationChange($event)"
            id="branchId"
            required
            v-model="branchId"
            class="form-select"
            aria-label="Default select example"
          >
            <option value="0">Selct Location</option>
            <option
              v-for="loc in locations"
              :key="loc.locationId"
              v-bind:value="loc.locationId"
            >
              {{ loc.locationName }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-xl-3 col-sm-6 col-12 mb-4">
        <div class="form-outline form-white mb-4">
          <label class="form-label" for="branchId">User</label>
          <select
            @change="onUserChange($event)"
            id="userId"
            required
            v-model="userId"
            class="form-select"
            aria-label="Default select example"
          >
            <option value="0">Selct User</option>
            <option
              v-for="user in usersList"
              :key="user.userid"
              v-bind:value="user.userid"
            >
              {{ user.displayName }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-xl-3 col-sm-6 col-12 mb-4"></div>
    </div>
    <div class="row">
      <div class="col-xl-4 col-sm-6 col-12 mb-4">
        <div class="card mh-100">
          <div class="card-body">
            <h5 class="card-title">Customer Feedback</h5>
            <div v-if="questions.length > 0">
              <BarChart
                :chartsData="weekChart"
                title="This Week"
                :key="componentKey1"
              ></BarChart>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-sm-6 col-12 mb-4">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Customer Feedback</h5>
            <div v-if="questions.length > 0">
              <BarChart
                :chartsData="monthChart"
                title="Month to Date"
                :key="componentKey2"
              ></BarChart>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-sm-6 col-12 mb-4">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Customer Feedback</h5>
            <div v-if="questions.length > 0">
              <BarChart
                :chartsData="yearChart"
                title="Year to Date"
                :key="componentKey3"
              ></BarChart>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-5" v-if="questions.length > 0">
      <div class="col-xl-4 col-sm-6 col-12 mb-4">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Sad Reasons</h5>
            <div v-if="questions.length > 0">
              <BarChart
                :chartsData="reasonsCount"
                title="Year to Date"
                :key="componentKey3"
              ></BarChart>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-8 col-sm-6 col-12 mb-4">
        <div class="card">
          <div class="card-body">
            <div v-if="pivotTableData.length > 0">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th
                      v-for="field in pivotTableData[0]"
                      :key="field"
                      scope="col"
                    >
                      {{ field }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in pivotTableData.filter(
                      (r) => r[0] !== 'Date'
                    )"
                    :key="item"
                  >
                    <td v-for="(field1, index) in item" :key="field1">
                      <div v-if="index != 7">
                        {{ field1 }}
                      </div>
                      <div v-else-if="field1 === '+'">
                        <i
                          class="text-success fas fa-arrow-up fa-1x"
                          style="color: white"
                        ></i>
                      </div>
                      <div v-else-if="field1 === '-'">
                        <i
                          class="text-danger fas fa-arrow-down fa-1x"
                          style="color: white"
                        ></i>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DataStore } from "@aws-amplify/datastore";
import { UserFeedback, FbUsers, Location } from "../models";
import BarChart from "../components/BarChart.vue";
import Card from "../components/Card.vue";

export default {
  name: "Dashboard",
  components: {
    BarChart,
    Card,
  },

  data() {
    return {
      branchId: "0",
      userId: "0",
      datacollection: null,
      loaded: false,

      totalVal: 0,
      veryHappy: 0,
      happy: 0,
      average: 0,
      sad: 0,
      verySad: 0,
      pivotTableData: [],

      weekChart: [],
      monthChart: [],
      yearChart: [],
      reasonsCount: [],

      questions: [],
      feedbacks: [],
      usersList: [],
      locations: [],
      componentKey1: Math.random(),
      componentKey2: Math.random(),
      componentKey3: Math.random(),
      componentKey4: Math.random(),
      componentKey5: Math.random(),
      componentKey6: Math.random(),
    };
  },
  methods: {
    onUserChange() {
      //console.log(event.target.value);
      this.fillData();
    },
    onLocationChange() {
      //console.log(event.target.value);
      this.fillData();
    },

    // random() {
    //   return (new Date()).getTime() + Math.floor(Math.random() * 10000).toString()
    // },

    randomNumber: function (val) {
      return new Date().getTime() + Math.floor(Math.random() * val).toString();
    },

    async getUsers() {
      const users = await DataStore.query(FbUsers);

      if (users.length > 0) {
        this.usersList = users;
        //console.log("questions", this.usersList);
      }
    },

    async getLocation() {
      const lols = await DataStore.query(Location);

      if (lols.length > 0) {
        this.locations = lols;
        //console.log("getLocation", this.locations);
      }
    },

    getPivotArray: function (dataArray, rowIndex, colIndex, dataIndex) {
      let fuResult = {},
        ret = [];
      let newCols = [];

      for (const element of dataArray) {
        if (!fuResult[element[rowIndex]]) {
          fuResult[element[rowIndex]] = {};
        }
        fuResult[element[rowIndex]][element[colIndex]] = element[dataIndex];
        //To get column names
        if (newCols.indexOf(element[colIndex]) == -1) {
          newCols.push(element[colIndex]);
        }
      }
      let item = [];
      //Add Header Row
      item.push("Date");
      item.push.apply(item, newCols);
      ret.push(item);
      item.push("Total");
      item.push("Variance");

      //Add content
      let lasttot = 0;
      for (let key in fuResult) {
        item = [];
        item.push(key);
        let tot = 0;
        for (const element of newCols) {
          tot = tot + (fuResult[key][element] || 0);
          item.push(fuResult[key][element] || "-");
        }
        item.push(tot);
        //item.push(lasttot);
        item.push(tot - lasttot > 0 ? "+" : "-");
        lasttot = tot;
        ret.push(item);
      }
      return ret;
    },

    async fillData() {
      const fields = [];
      this.totalVal = 0;
      this.veryHappy = 0;
      this.happy = 0;
      this.average = 0;
      this.sad = 0;
      this.verySad = 0;

      let date = new Date();
      date.setHours(0, 0, 0, 0);
      let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

      let first = date.getDate() - date.getDay(); // First day is the day of the month - the day of the week
      let last = first + 6; // last day is the first day + 6

      let firstdayOfWeek = new Date(date.setDate(first)).toISOString();
      let lastdayOfWeek = new Date(date.setDate(last)).toISOString();

      let les10Days = new Date();
      les10Days.setDate(les10Days.getDate() - 14);
      let last10Days = les10Days.toISOString();

      //console.log("firstdayofWeek", firstdayOfWeek);
      //console.log("lastdayOfWeek", lastdayOfWeek);
      //console.log("last10Days", last10Days);

      if (this.branchId != "0") {
        fields.push({
          fieldId: "location",
          predicate: "eq",
          value: this.branchId.toString(),
        });
      }
      //console.log("User Id", this.userId);

      if (this.userId != "0") {
        fields.push({
          fieldId: "user",
          predicate: "eq",
          value: this.userId,
        });
      }

      const criteria = (c) => {
        fields.forEach((item) => {
          const predicate = item.predicate || "eq";
          c[item.fieldId](predicate, item.value);
        });
        return c;
      };

      //console.log("criteria", criteria);

      const feedback = await DataStore.query(UserFeedback, criteria);

      //console.log("this.branchId", this.branchId);
      //console.log("fields", fields);
      // const posts = await DataStore.query(UserFeedback, (c) =>
      //   c.location(
      //     "eq",
      //     this.branchId == "0" ? c.location : this.branchId.toString()
      //   )
      // );
      // const members = await DataStore.query(UserFeedback, (m) => {
      //   if (this.branchId != "0") {
      //     m.location("eq", locationId.toString());
      //   } else return m;
      // });

      //   {
      //     fieldId: "location",
      //     predicate: "eq",
      //     value: this.branchId.toString(),
      //   },
      //   {
      //     fieldId: "user",
      //     predicate: "eq",
      //     value: "rifaz.marikkar@gmail.com",
      //   },
      // ];

      //console.log("criteria", fields);

      //const abc = await DataStore.query(UserFeedback, criteria);

      // console.log("abc", abc);

      if (feedback.length > 0) {
        //console.log("feedbackALL", feedback);

        //Test Code
        let helper = {};
        let tableData = feedback.reduce(function (r, o) {
          if (o.DateTime >= last10Days) {
            let key = o.DateTime.split("T")[0] + "-" + o.feedBackValue;
            if (!helper[key]) {
              helper[key] = {
                ksy: key,
                feedBack: o.feedBack,
                date: o.DateTime.split("T")[0],
                val: 1,
                feedBackValueId: o.feedBackValue,
                dateTime: o.DateTime,
                lastChangedAt: o._lastChangedAt,
              }; // create a copy of o
              r.push(helper[key]);
            } else {
              helper[key].val += 1;
            }
          }
          return r;
        }, []);

        //tableData.sort((a, b) => a.feedBackValueId - b.feedBackValueId);
        tableData.sort((a, b) => a.lastChangedAt - b.lastChangedAt);

        //console.log("tableData", tableData);

        let i = 0,
          output = [];
        while (i < tableData.length) {
          output.push([]);
          for (let key in tableData[i]) {
            output[output.length - 1].push(tableData[i][key]);
          }
          i++;
        }

        output.sort((a, b) => a[2] - b[2]);

        this.pivotTableData = this.getPivotArray(output, 2, 1, 3);

        //console.log("pivotTableData", this.pivotTableData);

        //Test Code

        this.questions = feedback;

        let reasons = [];
        let reasonsSum = [];
        let result = [];

        feedback.forEach((val) => {
          if (val.reasons.length > 0) {
            reasons.push(...val.reasons);
          }
        });

        // console.log("reasons", reasons);

        reasons.reduce(function (res, value) {
          if (!res[value]) {
            res[value] = {
              feedBack: value,
              val: 0,
            };
            reasonsSum.push(res[value]);
          }
          res[value].val += 1;

          return res;
        }, {});

        //console.log("reasons", reasonsSum);

        this.reasonsCount = reasonsSum.reverse();

        feedback.reduce(function (res, value) {
          if (!res[value.feedBack]) {
            res[value.feedBack] = {
              feedBack: value.feedBack,
              val: 0,
              timeval: value._lastChangedAt,
              feedBackValueId: value.feedBackValue,
            };
            result.push(res[value.feedBack]);
          }
          res[value.feedBack].val += 1;

          return res;
        }, {});

        //console.log("result", result);

        let weekResult = [];
        feedback.reduce(function (res, value) {
          if (
            value.DateTime > firstdayOfWeek &&
            value.DateTime <= lastdayOfWeek
          ) {
            if (!res[value.feedBack]) {
              res[value.feedBack] = {
                feedBack: value.feedBack,
                val: 0,
                timeval: value._lastChangedAt,
                feedBackValueId: value.feedBackValue,
              };
              weekResult.push(res[value.feedBack]);
            }
            res[value.feedBack].val += 1;
          }
          return res;
        }, {});

        //console.log("weekResult", weekResult);

        weekResult.sort((a, b) => a.feedBackValueId - b.feedBackValueId);
        this.weekChart = weekResult.reverse();

        let monthResult = [];
        feedback.reduce(function (res, value) {
          if (
            value.DateTime > firstDay.toISOString() &&
            value.DateTime <= lastDay.toISOString()
          ) {
            if (!res[value.feedBack]) {
              res[value.feedBack] = {
                feedBack: value.feedBack,
                val: 0,
                timeval: value._lastChangedAt,
                feedBackValueId: value.feedBackValue,
              };
              monthResult.push(res[value.feedBack]);
            }
            res[value.feedBack].val += 1;
          }
          return res;
        }, {});

        monthResult.sort((a, b) => a.feedBackValueId - b.feedBackValueId);
        this.monthChart = monthResult.reverse();

        //console.log("monthResult", monthResult);

        //console.log("result", result);

        result.sort((a, b) => a.feedBackValueId - b.feedBackValueId);

        this.yearChart = result.reverse();

        result.forEach((fb) => {
          switch (fb.feedBackValueId) {
            case 1:
              this.verySad = fb.val;
              break;
            case 2:
              this.sad = fb.val;
              break;
            case 3:
              this.average = fb.val;
              break;
            case 4:
              this.happy = fb.val;
              break;
            case 5:
              this.veryHappy = fb.val;
              break;
          }
        });

        this.totalVal = result.reduce(function (previousValue, currentValue) {
          return previousValue + currentValue.val;
        }, 0);

        //console.log("sum", sum);

        // this.chartData = {
        //   labels: fbText,
        //   datasets: [
        //     {
        //       label: "Customer Feedback",
        //       backgroundColor: "#f87979",
        //       data: count,
        //     },
        //   ],
        // };
      } else {
        this.questions = [];
        this.totalVal = 0;
        (this.veryHappy = 0), (this.happy = 0);
        this.average = 0;
        this.sad = 0;
        this.verySad = 0;
      }
      this.componentKey1 += 1;
      this.componentKey2 += 1;
      this.componentKey3 += 1;
      this.componentKey4 += 1;
      this.componentKey5 += 1;
      this.componentKey6 += 1;
    },

    async fetchData(fields) {
      /* 
        More configurable wrapper for Datastore.query calls 
        @param props: {model: Model, criteria: [{fieldId, predicate, value}]}.
         */
      try {
        let criteria;
        if (fields && typeof fields === "object") {
          criteria = (c) => {
            fields.forEach((item) => {
              const predicate = item.predicate || "eq";
              c[item.fieldId](predicate, item.value);
            });
            return c;
          };
        }
        return await DataStore.query(UserFeedback, criteria);
      } catch (e) {
        console.log(e);
        //throw new Error(e);
      }
    },
  },
  async created() {
    await this.getUsers();
    await this.fillData();
    await this.getLocation();
    this.$emit("oncustServicesUpdated", true);
  },

  async mounted() {
    await this.fillData();
    //console.log("mounted");
  },
};
</script>

<style></style>
