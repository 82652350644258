<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-4 col-sm-6 col-12 mb-4"></div>
      <div class="col-xl-4 col-sm-6 col-12 mb-4 mt-5">
        <authenticator>
          <template v-slot="{ user }">
            <h5>Welcome {{ user.username }}!</h5>
            <button class="m-2" @click="goToQuestion()">Question</button>
            <button v-if="adminGrp" class="m-2" @click="goToDashboard()">
              Dashbord
            </button>
            <button class="m-3" @click="goTosignOut()">Sign Out</button>
            <img alt="banner" class="col-12" :src="bannerImg" />
          </template>
        </authenticator>
      </div>
      <div class="col-xl-4 col-sm-6 col-12 mb-4"></div>
    </div>
  </div>
</template>

<script>
//import LoginCompNew from '../components/LoginCompNew.vue'
import { Authenticator } from "@aws-amplify/ui-vue";
import "@aws-amplify/ui-vue/styles.css";
import { Auth } from "@aws-amplify/auth";

export default {
  name: "Login",
  components: { Authenticator },
  methods: {
    goToQuestion() {
      this.$router.push("/question");
    },
    goToDashboard() {
      this.$router.push("/dashboard");
    },
    goTosignOut() {
      Auth.signOut();
      this.$router.push("/dashboard");
    },
  },
  data() {
    return {
      adminGrp: false,
      bannerImg: require("../assets/data-visualization.png"),
    };
  },

  async mounted() {
    const user = await Auth.currentAuthenticatedUser();

    this.adminGrp = false;

    if (user.signInUserSession.accessToken.payload["cognito:groups"]) {
      this.adminGrp =
        user.signInUserSession.accessToken.payload["cognito:groups"][0] ==
        "Admin";
    }

    //console.log("group", this.adminGrp);
  },
};
</script>

<style>
.amplify-tabs {
  display: none;
}
.amplify-button[data-variation="primary"] {
  background: #e31d1a;
}

.amplify-tabs-item[data-state="active"] {
  border-color: #e31d1a;
}
</style>
